import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Commons } from 'src/app/_helpers/commons';
import { AuthenticationService } from 'src/app/_services/authentication.service';
// import { BUTTON_TEXTS } from 'src/app/_helpers/messages';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  //btnTexts = BUTTON_TEXTS;
  message: string = "";
  returnUrl: string="";

  public internalError=false;
  public showBuffer: boolean = false;
  public bufferMessage: string = "";
  public backIcon: any = {
    label: "Ico_ChevronGauche.svg",
    path: "https://smagstorage.blob.core.windows.net/images/SmagAccount/Ico_ChevronGauche.svg"
  };

  constructor(private route: ActivatedRoute,
    private authentificationService: AuthenticationService,
    private common:Commons ) { }

  ngOnInit() {
    this.common.token=localStorage.getItem('token')||"";
    this.common.refresh_token = localStorage.getItem('refresh_token')||"";
    this.message = this.route.snapshot.queryParamMap.get('message')||"";
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')||"";
    this.common.initTranslationService();
    this.internalError=this.common.token=="";
  }

  back(): void{
    //this.router.navigate(['/login']);
    this.showBuffer=true;
    this.redirectToZendesk();
  }

  redirectToZendesk(){
    this.authentificationService.getZendeskToken().subscribe(redirectUrl => {
      localStorage.clear();
       window.location.href = redirectUrl.toString();
    },
    err => {
      this.internalError=true;
      this.showBuffer = false;
      this.message=err.error.Message;

    });
   
  }

}