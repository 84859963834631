import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { APPLICATION_OPERATIONAL_ENDPOINTS } from "../_helpers/routes";
import { Identity } from "../_models/authentification";

@Injectable({
    providedIn: 'root'
  })
  
export class ApplicationService
{
    constructor(private http: HttpClient) { }
    
    public async getApplicationById(id : string)
    {
        const url = `${environment.API_URLS.smag_application_operational}${APPLICATION_OPERATIONAL_ENDPOINTS.get_applications}/${id}`;
        return await this.http.get<Identity>(url);
    }

}