declare var window: any;

//Decommenter pour tester en local
 //window.env = {};
 //window["env"]["API_URLS"] = {};
 
 export const environment = {
 name: window["env"]["name"] || 'dev',
  production: window["env"]["production"] || false,
  ApiManagerKey: window["env"]["ApiManagerKey"] || '57b5332a5d9e40d9bcad6a294558305f',
  appId: window["env"]["appId"] || '5ce3d147-9cf9-4ba7-af6b-f98baeea516b',
  SMAG_BUTTON_SCRIPT : window["env"]["SMAG_BUTTON_SCRIPT"]||'https://dev-smag-party-button-app-v1.azurewebsites.net/Scripts/SmagButton.js?',
  SMAG_BUTTON_CSS : window["env"]["SMAG_BUTTON_SCRIPT"]||'https://dev-smag-party-button-app-v1.azurewebsites.net/Content/SmagButton.css?',
  API_URLS: {
    smag_party_operational: window["env"]["API_URLS"]["smag_party_operational"] || 'https://dev-smag-application-api-management.azure-api.net/party-operational-extended/v1/',
    smag_authentication_operational: window["env"]["API_URLS"]["smag_authentication_operational"] || 'https://dev-smag-application-api-management.azure-api.net/authentication-operational-extended/v1/',
    smag_application_operational:  window["env"]["API_URLS"]["smag_application_operational"] || 'https://dev-smag-application-api-management.azure-api.net/application-operational-extended/v1/'
  }
};