export const AUTHENTICATION_OPERATIONAL_ENDPOINTS = {
    build_url: "api/authentication/build_url",
    add_comment_to_zendesk_ticket: "api/specifics/zendesk_ticket",
    current_user:"api/subscriptions/current_user",
    refresh_token:"api/oauth2/refresh_token",
    get_zendesk_token:"api/specifics/get_zendesk_token"
  };
  export const PARTY_OPERATIONAL_ENDPOINTS = {
    user_profiles: "api/user_profiles"
  };
  export const CLAIMS_KEY = {
    EMAIL: "https://smag.authentication.operational.com/email"
  }
  export const APPLICATION_OPERATIONAL_ENDPOINTS = {
    get_applications : "api/applications"
  }