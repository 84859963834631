<smag-buffer *ngIf="showBuffer" [msg]="bufferMessage"></smag-buffer>
<div class="get-token-main">
  <div class="get-token-app-smag-button-container"></div>
    <div class="token-logo"></div>
    <div class="token-page">
    <div class="section">
      <div class="checkbox custom-checkbox">
        <div class="checkbox-items-container">
            <mat-checkbox name="displayed_in_smag_button" (click)="handleChecked($event)"
                class="checkbox-item">
                <p class ="txtAuthorisation">{{ 'INTERFACE.VALIDATION_SENTENCE' | translate }}</p>
            </mat-checkbox>
            <p>{{ 'INTERFACE.EXPLANATION_SENTENCE' | translate }}</p>
        </div>
      </div>
      <div style="text-align: center;">
      <form>
        <mat-form-field appearance="fill">
          <mat-label>{{ 'INTERFACE.SELECT_BAR_LABEL' | translate }}</mat-label>
          <mat-select [(ngModel)]="selected_id" name="application" [disabled]="!isChecked">
            <mat-option *ngFor="let identity of identities" [value]="identity.application_id">
              {{ identity.application_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
      <button type="button" (click)="refreshToken()" class="custom-button" [disabled]="!(selected_id && isChecked)">{{ 'INTERFACE.VALIDATION_BUTTON' | translate }}</button>
    </div>
  </div>
</div>