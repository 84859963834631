import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { CallbackComponent } from './_components/callback/callback.component';
import { LoginComponent } from './_components/login/login.component';
import { AccueilComponent } from './_components/accueil/accueil.component';
import { ErrorComponent } from './_components/error/error.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'accueil',
    pathMatch: 'full',
  },
  {
    path: 'accueil',
    component:AccueilComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'callback',
    component:CallbackComponent
  },
  {
    path: 'login',
    component:LoginComponent
  },
  {
    path: 'error',
    component:ErrorComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
