
import { Injectable, Injector } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateParser } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { TranslationService } from "../_services/translation.service";
import { defaultTextDictionary } from "./messages";

export class CustomTranslationParser extends TranslateParser {

    public templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;
  
    interpolate(expr: string, params?: any): string {
      if(params && expr) {
        return expr.replace(this.templateMatcher, params.value)
      }
      return expr;
    }
  
    getValue (target: any, key: string): any {
      const [blockId, elementId] = key.split('.');
      if(!target || !target.ModulesTranslation) return;
      const blockTranslations = target.ModulesTranslation.find((moduleTranslation: any) => moduleTranslation.BlockId == blockId);
      if(!blockTranslations) return;
      const translations = blockTranslations.Translations;
      if(!translations) return;
      const foundTranslations = translations.find((element :any) => element.Code === elementId);
      if(!foundTranslations) return;
      return foundTranslations.Value;
    }
}

@Injectable()
export class LanguageLoader implements TranslateLoader {
  constructor(private injector: Injector, private translationService: TranslationService){}
    getTranslation(lang: string): Observable<any> {
      return this.translationService.getJsonFile();
    }
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        let temporaryContextDict = defaultTextDictionary;
        for(let key of params.key.split('.'))
          temporaryContextDict = temporaryContextDict;
        return temporaryContextDict;
    }
  }