import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { 
    HttpEvent, HttpRequest, HttpHandler, 
    HttpInterceptor, HttpErrorResponse 
  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  
    constructor(private injector: Injector){ }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );    
    }
}