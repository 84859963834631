import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AUTHENTICATION_OPERATIONAL_ENDPOINTS } from '../_helpers/routes';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  getAuthenticationUrl() {

    const url = `${environment.API_URLS.smag_authentication_operational}${AUTHENTICATION_OPERATIONAL_ENDPOINTS.build_url}`;
    const body = {
      "AdditionalValues": {
        "application_id": environment.appId,
        "displaySignUp": "0",
        "displayLogin": "1",
        "displayInfo": "0"
      }
    };

    return this.http.post(url, body).toPromise()
      .then(res => res)
      .catch(err => err.error.message || err.statusText);
  }

  getZendeskToken()
  {
    const url = `${environment.API_URLS.smag_authentication_operational}${AUTHENTICATION_OPERATIONAL_ENDPOINTS.get_zendesk_token}`;
    return this.http.get(url);
  }

  addCommentZendeskTicket(selectedApplication: string, currentUserEmail: string, urlToSend: string, ticketId: string)
  {
    const url = `${environment.API_URLS.smag_authentication_operational}${AUTHENTICATION_OPERATIONAL_ENDPOINTS.add_comment_to_zendesk_ticket}`;
    const body = {
        "currentUserEmail": currentUserEmail,
        "urlToSend": urlToSend,        
        "selectedApptype": selectedApplication,
        'ticketId': ticketId
      };
    return this.http.put(url, body).pipe(
      retry(1)
    );
  }
}

