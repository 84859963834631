import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Commons } from './commons';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private common:Commons) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let header: any = {};

        // add authorization header with jwt token if available
        let token = this.common.token;
        if (token && token.length > 0) {
            header["SmagAuthorization"] = `Bearer ${token}`;
        }
        // add ApiManagerKey
        header["Ocp-Apim-Subscription-Key"] = environment.ApiManagerKey;

        request = request.clone({
            setHeaders: header
        });
        return next.handle(request).pipe(
            tap(res => {
                if (res instanceof HttpResponse) {
                    
                }
            })
        );
    }
}