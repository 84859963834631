import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationType, Notification } from 'src/app/_models/notification';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {

  public icon: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: Notification) { 
    this.icon = notification.type === NotificationType.success ? '': ''
  }

  ngOnInit(): void {
  }

}
