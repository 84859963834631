export const defaultTextDictionary = {
    INTERFACE: {
        VALIDATION_BUTTON : "Valider",
        SELECT_BAR_LABEL : "Select an application",
        VALIDATION_SENTENCE :"I authorize Smag to use my Smag account for the duration of my support ticket resolution",
        EXPLANATION_SENTENCE:"Your Smag account will be used only for Smag technical support purposes, no other use will be made"
    },
    MESSAGE : {
        SUCCESS_MESSAGE : "Enregistrement effectué. Un email de confirmation vous a été envoyé",
        ERROR_MESSAGE : "L'enregistrement a échoué veuillez réessayer"
    }
}