import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    
  login_url:SafeResourceUrl = "";
  
  constructor(
    private authService:AuthenticationService, 
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      var ticketId = (params["returnUrl"].toString().toUpperCase().split('TICKETID=')[1]!=undefined)?params["returnUrl"].toString().toUpperCase().split('TICKETID=')[1]:"";
      localStorage.setItem('ticketId', ticketId);
    });
    this.authService.getAuthenticationUrl().then(res => this.login_url = this.sanitizer.bypassSecurityTrustResourceUrl(res) );
  }

}
