import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Commons } from '../_helpers/commons';
import { Router } from '@angular/router';
declare var SmagButton: any;

@Injectable({
  providedIn: 'root'
})
export class SmagButtonService {
  public smagButtonInstance: any;
  private smagButtonContainerSelector:string=".get-token-app-smag-button-container";
  private _token:string="";
  private _refresh:string="";

  public get token(): string {
    return this._token;
  }
  public set token(value: string) {
    this._token = value;
  }

  public get refresh(): string {
    return this._refresh;
  }
  public set refresh(value: string) {
    this._refresh = value;
  }

  constructor(private router: Router,private common:Commons) {}

  public loadSmagButtonLibraries(){
    var head = document.getElementsByTagName('head')[0];
  
    this.token=this.common.token;
    this.refresh=this.common.refresh_token;

    //Check if smagbutton script is already in html head
    if(this.SmagButtonInScript(head))
      return;

    //smagbutton script is not in head...load it
    var smagbuttonscript = document.createElement('script');
    smagbuttonscript.setAttribute('id', 'smagbutton');
    smagbuttonscript.setAttribute('type', 'text/javascript');
    smagbuttonscript.setAttribute('src', environment.SMAG_BUTTON_SCRIPT+Date.now());
    smagbuttonscript.onload = this.smagButtonLoadedHandler.bind(this);
  
    head.appendChild(smagbuttonscript);
  
  }


  public OnSignOut(){
    localStorage.clear();
    this.common.token="";
    this.common.refresh_token="";
    this.token="";
    this.refresh="";

    this.router.navigate(['/login'], {});
  }

  public OnSignIn(){

    if(this.smagButtonInstance){
      //localStorage.setItem('token', this.smagButtonInstance.token);
      //localStorage.setItem('refresh_token', this.smagButtonInstance.refresh_token);
      this.common.token=this.smagButtonInstance.token;
      this.common.refresh_token=this.smagButtonInstance.refresh_token;
      this.token =this.smagButtonInstance.token;
      this.refresh = this.smagButtonInstance.refresh_token;
    }
  }

  private SmagButtonInScript(header: any):Boolean{
      
    for(var i=0; i<header.children.length;i++){
      if(header.children[i].id=="smagbutton")
        return true;
    };

    return false;
  }

  private smagButtonLoadedHandler() {
    var that=this;

    this.smagButtonInstance = new SmagButton(this.smagButtonContainerSelector, this.token, this.refresh, environment.appId,true );
    this.smagButtonInstance.addSignOutCallback(this.OnSignOut.bind(that));
    this.smagButtonInstance.addSignInCallback(this.OnSignIn.bind(that));
  }

  public updateTokens(token:string, refreshToken:string){
    this.token = token;
    this.refresh = refreshToken;
  }

}

