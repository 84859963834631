import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PARTY_OPERATIONAL_ENDPOINTS } from "../_helpers/routes";
import { Language } from "../_models/language";

@Injectable({
    providedIn: 'root'
  })
  export class UserProfileService {
  
    constructor(private http: HttpClient) { }

    private baseUrl: string = `${environment.API_URLS.smag_party_operational}${PARTY_OPERATIONAL_ENDPOINTS.user_profiles}`;

    async getCurrentUserLanguage() 
    {  
      return await this.http.get<Language>(`${this.baseUrl}/current_user/language`).toPromise();
    }
  }