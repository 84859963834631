import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Commons } from 'src/app/_helpers/commons';


@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private common:Commons,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    ) { }

  ngOnInit() {
    let url:any;
    var ticketId=localStorage.getItem("ticketId") || "";
    localStorage.removeItem("ticketId");
    this.processQueryString(this.route);
    this.common.initTranslationService();

    if(ticketId!=""){
      //this.router.navigate(['/accueil'],{ queryParams: { ticketId: ticketId } });
      url = this.router.createUrlTree(['accueil'],{ queryParams: { ticketId: ticketId } });
      window.open(url.toString(), '_top');
    }
    else{
      this.translateService.get("MESSAGE.ERROR_MISSING_TICKET_ID").subscribe(trad =>
        {
          //this.router.navigate(['/error'],{ queryParams: { message: trad } });
          url = this.router.createUrlTree(['error'],{ queryParams: { message: trad } });
          window.open(url.toString(), '_top');
        });
    }
    
  }

  private processQueryString(currentRoute: ActivatedRoute): void {
    const tokenFromUri = currentRoute.snapshot.queryParamMap.get('token');
    const refreshTokenFromUri = currentRoute.snapshot.queryParamMap.get('refresh_token');
    tokenFromUri ? localStorage.setItem('token', tokenFromUri) : localStorage.removeItem('token');
    refreshTokenFromUri ? localStorage.setItem('refresh_token', refreshTokenFromUri) : localStorage.removeItem('refresh_token');
    this.common.token=tokenFromUri||"";
    this.common.refresh_token=refreshTokenFromUri||"";

  };

}