import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {default as resourceTrad} from "../_models/translation.json";
import { UserProfileService } from "./user-profile.service";


@Injectable({
    providedIn: 'root'
  })
  export class TranslationService {
    currentLanguage: string =  "";
    constructor(private http: HttpClient, private userProfileService: UserProfileService) { 
    }

    getJsonFile(){
          var result = new Observable(observer => {
            var module = resourceTrad.ModuleLang[1];
            this.userProfileService.getCurrentUserLanguage().then(lang => 
              {
                for (let index = 0; index < resourceTrad.ModuleLang.length; index++) {

                  if(resourceTrad.ModuleLang[index].lang == lang.Code)
                  {
                    module = resourceTrad.ModuleLang[index];
                  }
                }
                observer.next(module != null ? module.trad[0] : "");
                observer.complete();
              });
          });
          return result;
    }
  }